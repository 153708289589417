<template>
  <div class="spoiler-block">
    <div class="wrapper flex flex-column gap-16">
      <h2 v-if="title.length > 0" class="text-subheader">{{ title }}</h2>

      <div class="spoiler-block__list flex flex-column gap-8 flex-ai-center">
        <div
          v-for="(spoiler, id) in texts"
          :key="`article_spoiler_${id}`"
          class="spoiler-block__item"
          :class="{
            'spoiler-block__item--open': isOpenSpoilerId === id,
          }"
        >
          <div class="spoiler-block__header flex gap-32" @click="openCurrentSpoiler(id)">
            <div class="flex-11a">{{ spoiler.title }}</div>

            <NuxtIcon
              class="spoiler-block__header-icon flex-00a"
              :name="isOpenSpoilerId === id ? 'IconMinus' : 'IconPlus'"
            />
          </div>
          <transition
            name="spoiler"
            mode="in-out"
            @enter="enter"
            @leave="leave"
            @before-enter="beforeEnter"
            @before-leave="beforeLeave"
          >
            <div v-if="isOpenSpoilerId === id" class="spoiler-block__content">
              <CSpecialistCardSmall
                v-if="spoiler.doctor"
                v-bind="spoiler.doctor"
                :subtitle="spoiler.answer"
              />
              <CTextBlock :text="spoiler.text" />
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { BlockSpoilerText } from '@/types/pages';
import { ref } from '#imports';
import './styles.scss';
import CTextBlock from '~/components/blocks/textBlock/CTextBlock.vue';
import CSpecialistCardSmall from '~/components/blocks/specialistCardSmall/CSpecialistCardSmall.vue';

interface Props {
  title: string;
  texts: BlockSpoilerText[];
}

defineProps<Props>();

const isOpenSpoilerId = ref(0);

const openCurrentSpoiler = (id: number) => {
  isOpenSpoilerId.value = id;
};

const beforeEnter = (el: HTMLElement) => {
  el.style.opacity = '0';
  el.style.height = '0';
};
const enter = (el: HTMLElement) => {
  el.style.height = `${el.scrollHeight}px`;
  el.style.opacity = '1';
};
const beforeLeave = (el: HTMLElement) => {
  el.style.height = `${el.scrollHeight}px`;
  el.style.opacity = '1';
};
const leave = (el: HTMLElement) => {
  el.style.opacity = '0';
  el.style.height = '0';
};
</script>
